import { useLayoutEffect } from "react";
import { useLocation } from "react-router-dom";

const ScrollToTop = () => {
  const { pathname } = useLocation();

  useLayoutEffect(() => {
    // Disable the browser's automatic scroll restoration
    const previousScrollRestoration = window.history.scrollRestoration;
    window.history.scrollRestoration = "manual";

    // Scroll to the top when the route changes
    window.scrollTo(0, 0);

    // Restore the scroll restoration behavior when component unmounts
    return () => {
      window.history.scrollRestoration = previousScrollRestoration;
    };
  }, [pathname]);

  return null;
};

export default ScrollToTop;
