import React, { useEffect, useState } from 'react'; // Added useState for managing the animation state
import './HeroSection.css';
import video from './video-665.mp4';
import Footer from '../../components/Footer/Footer';
import FooterSocials from '../../components/FooterSocials/FooterSocials';
import BackgroundImage from '../../components/BackgroundImage/BackgroundImage';
import WhoAreWe from './sections/WhoAreWe';
import OurClients from './sections/OurClients';
import { useTranslation } from 'react-i18next';

function HeroSection() {
    const { t } = useTranslation(); // useTranslation hook to get translations
    const [isVisible, setIsVisible] = useState(false); // State to trigger animation

    useEffect(() => {
        // Delay showing the section to allow for a smoother animation
        setTimeout(() => {
            setIsVisible(true);
        }, 200); // Optional: Add a slight delay before starting the animation
    }, []);

    return (
        <>
            <div className={`hero-container ${isVisible ? 'show' : ''}`}>
                <video
                    src={video}
                    autoPlay
                    loop
                    muted
                    playsInline
                    poster="path/to/your-poster-image.jpg"
                    className="video-container"
                    preload="auto"
                >
                    Your browser does not support the video tag.
                </video>
                <h1>{t('home.title')}</h1>
                <p>{t('home.desc')}</p>
                <div className='hero-btns'>
                    <section id="section02" className="demo">
                        <a href="#who-are-we"><span></span></a>
                    </section>
                </div>
            </div>

            <WhoAreWe />
            <OurClients />
            <BackgroundImage />
            <FooterSocials />
            <Footer />
        </>
    );
}

export default HeroSection;
