import React, { useState } from 'react';
import './Soon.css';
import logo from './logo-white2.png';
import '@fortawesome/fontawesome-free/css/all.min.css';
import backgroundImage from './dark.png';

function Soon() {
    const [email, setEmail] = useState('');
    const [submitted, setSubmitted] = useState(false);
    const [error, setError] = useState(false);

    const handleSubmit = async (e) => {
        e.preventDefault();
        setError(false);
        
        try {
            const response = await fetch('https://formspree.io/f/xvgoogob', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ email }),
            });
            
            if (response.ok) {
                setSubmitted(true);
            } else {
                setError(true);
            }
        } catch (error) {
            setError(true);
        }
    };

    return (
        <div className="soon-container" style={{ backgroundImage: `url(${backgroundImage})` }}>
            <div className="soon-header">
                <img src={logo} alt="Company Logo" className="soon-logo" />
                <h1 className="soon-title">Coming Soon</h1>
                <p className="soon-text">We are working hard to bring something amazing! Stay tuned.</p>
                <p className="soon-text subtle-message">Transform your social media presence.</p>
            </div>

            <div className="soon-content">
                {!submitted && <p className="subscribe-message">Don't Miss Out - Subscribe for Early Access</p>}
                
                {submitted ? (
                    <p className="success-message">Thank you! You've successfully subscribed.</p>
                ) : (
                    <form onSubmit={handleSubmit} className="soon-form">
                        <input
                            type="email"
                            name="email"
                            className="soon-input"
                            placeholder="Enter your email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            required
                        />
                        <button type="submit" className="soon-button">Subscribe</button>
                    </form>
                )}

                {error && <p className="error-message">Oops! Something went wrong. Please try again.</p>}
            </div>

            {/* Instagram icon and social media text */}
            <div className="social-follow">
                <p className="social-text">Follow us on socials</p>
                <a 
                    href="https://www.instagram.com/nexgensolutions.pt/" 
                    target="_blank" 
                    rel="noopener noreferrer"
                    className="instagram-icon-link"
                >
                    <i className="fab fa-instagram instagram-icon"></i>
                </a>
            </div>
        </div>
    );
}

export default Soon;
